<template>
  <div class='compliance-appeal'>
    <h2 class="title">{{$t('complianceAppeal')}}</h2>
    <div class="compliance-appeal__content">
      <div class="compliance-appeal__descr">
        <p>
          {{$t('complianceContent1')}}<br><br>
          {{$t('complianceContent2')}}<br><br>
          {{$t('complianceContent3')}}
        </p>
      </div>
      <div class="compliance-appeal__person">
        <img src="@/assets/img/compliance/azamat.jpg" alt="image">
        <div class="compliance-director">
          <div class="compliance-director__name">{{$t('complianceName')}}</div>
          <div class="compliance-director__position">{{$t('compliancePosition')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ComplianceAppeal'
  }
</script>